<template>
  <div class="container">
    <div class="result">
      <div class="result_block">
        <h4>{{ $t("banner.summary_rental_data") }}</h4>
        <div class="links_block">
          <a
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1WDFqZ4OpdO3JuD3Ol8pWM36H3ON4KqW3/edit?usp=sharing&ouid=102940429791109518911&rtpof=true&sd=true"
            class="btn_banner btn primary-button"
            >{{ $t("about_us.btn_result") }}</a
          >
        </div>
        <!-- <a target="_blank" href="https://docs.google.com/spreadsheets/d/15ljXlT2x_H9INE1x-eXQjzfiFvoSxsrH/edit?usp=sharing&ouid=114542045444308622604&rtpof=true&sd=true" class="btn primary-button btn-lg">{{$t('banner.not_confirmed')}}</a> -->
      </div>
      <div class="charts_home">
        <h3 style="text-align: center">{{ $t("charts.title") }}</h3>
        <Bar :data="chartData" :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [
          "2018-2019",
          "2019-2020",
          "2020-2021",
          "2021-2022",
          "2022-2023",
          "2023-2024(данные по июль 2024г)",
        ],
        datasets: [
          {
            label: "Аренда",
            backgroundColor: "#0088e1",
            data: [
              17522099, 36957653.6, 38212723, 46849703.3, 54193251.5,
              56895386.41,
            ],
          },
        ],
      },
      chartOptions: {
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                let value = context.dataset.data[context.dataIndex];
                return value.toLocaleString("ky-KG", {
                  style: "currency",
                  currency: "Com",
                });
              },
            },
            displayColors: false, // Убрать цветовые прямоугольники
            borderWidth: 0, // Убрать границы
            titleFontSize: 0, // Убрать размер заголовка
            bodyFontSize: 12, // Установить размер текста
            bodyFontColor: "#000", // Цвет текста
          },
          legend: {
            display: false, // Скрыть легенду
          },
        },
        hover: { mode: null },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toLocaleString("ky-KG", {
                  style: "currency",
                  currency: "Com",
                });
              },
            },
          },
        },
        barThickness: 60,
        animation: {
          duration: 4000, // Продолжительность анимации в миллисекундах
          easing: "easeInOutQuart", // Тип анимации
        },
        // barThickness: 60 // ! Установка ширины столбцов в пикселях
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  methods: {
    redirectToExcel() {
      // Метод для перенаправления пользователя
      window.open(
        "https://docs.google.com/spreadsheets/d/1Om_ISeBd3xLzIUdAVRvPOMIS6LAG5rfQ/edit?usp=sharing&ouid=114542045444308622604&rtpof=true&sd=true",
        "_blank"
      ); // Замените на вашу ссылку
    },
  },
};
</script>

<style scoped>
.charts_home {
  display: flex;
  flex-direction: column;
  /* justify-content: ; */
  width: 60%;
}
.result {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
}
.result_block {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  width: 30%;
}

.result_block h4 {
  font-size: 2.3em;
}

.btn_banner {
  width: 60%;
}
.btn_banner:hover {
  color: green !important;
}
.result_block a:visited {
  color: white;
}
.fa-file-excel {
  font-size: 200%;
  color: green;
  transition: 400ms;
}
.fa-file-excel:hover {
  color: #0088e1;
}

.btn_banner:hover .fa-file-excel {
  color: #0088e1;
}

.links_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 3%;
}

@media (max-width: 750px) {
  .result {
    display: flex;
    flex-direction: column;
    gap: 40px 0;
  }
  .charts_home {
    width: 100%;
  }
  .result_block {
    text-align: center;
    width: 100%;
  }
  .links_block {
    justify-content: center;
  }
}
@media (min-width: 450px) and (max-width: 800px) {
  .result_block h4 {
    font-size: 1.5em;
  }
}
@media (max-width: 450px) {
  .result_block h4 {
    font-size: 1.4em;
  }
}
</style>
