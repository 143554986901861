<template>
  <div>
    <div class="pages-title">
      <h2 class="regulations-page-title">{{$t('regulations_page.title')}}</h2>
    </div>
<div class="container-regulations">
  <div class="hint-title">{{$t('regulations_page.title-two')}}</div>
    <ul class="list-group">
      <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1bRZlcWrqkhNWO2vS51-JSRP7iy3r2F_3/view?usp=sharing">
        {{$t('regulations_page.2024-2025-order')}}
        
      </a>
    </li>
      <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://docs.google.com/document/d/17Eze63sJC2ZDaoJfGd3eMpCF1QEEXoGh/edit?usp=sharing&ouid=117476319524205940709&rtpof=true&sd=true">
        {{$t('regulations_page.2024-2025')}}
      </a>
    </li>
      <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1L2h_enEtqMfLr0rc5A5RNVHihJSlaydX/view">
        {{$t('regulations_page.the_procedure_for_renting_textbooks_in_educational_organizations')}}
      </a>
    </li>
    <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1vNXMAak8-erGsJi_IDWEAYxkn6I7H_S1/view?usp=sharing">
        {{$t('regulations_page.rent')}}
      </a>
    </li>
    <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1IRJX_vhdtel0LKGU3pGZb5P6n-gNArsE/view">
        {{$t('regulations_page.rental_order_for_2022/2023_academic_year')}}
      </a>
    </li>
  
    <li class="list-group-item">
      <i class='fa-solid fa-book-open-reader'></i> 
      <a href="https://drive.google.com/file/d/1n2D2cFJBlxa9JUO9-61SbgrnAwkZpUYa/view">
        {{$t('regulations_page.order_of_the_MES_No_1003/1_dated_august_2_2018')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/17KchKd9vOZctgCfjHtvovrGGgIYX7nxI/view">
        {{$t('regulations_page.guidelines_for_renting_textbooks_in_educational_institutions')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="#">
        {{$t('regulations_page.implementation_schedule_for_textbook_rental')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1OBirC_1qq5bpbAgY8_0ETAb5d8MA6MuJ/view">
        {{$t('regulations_page.methodology_for_calculating_the_rent_for_the_use_of_textbooks')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1GGNqBy0R_7CBfvpX-AjoDQKMjKejjRYt/view">
        {{$t('regulations_page.list_of_textbooks')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1h50rHki1cgP8QI8OjZcrrRfqxCcR2JbA/view">
        {{$t('regulations_page.order_of_no_1445/1_dated_november_23_2018')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1ahFNHD9wkCM6KaAWf3qDy5guwnDyPLyl/view">
        {{$t('regulations_page.order_No_1476/1_dated_December_3_2018')}}  
      </a>
      </li>
      <li class="list-group-item">  
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1g87aIxB2Dez8CXgYP5iJQgEzFYCj54OC/view">
        {{$t('regulations_page.order_1553/1_of_December_17_2018')}} 
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/11W-geDl1khAmtEwe8MnDWJECDGgGT9MT/view">
        {{$t('regulations_page.order_25/1_dated_January_17_2019')}} 
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1TqH-czHZe1h7bn9mrmGJwKkMbOZXgobv/view">
        {{$t('regulations_page.order_1003/1_dated_August_22_2019')}} 
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1ocxTy9f6JWGNP91CqkDMXGpdD_wXbsR_/view">
        {{$t('regulations_page.order_1049/1_dated_September_5_2019')}}
      </a>
    </li>
    <li class="list-group-item"> 
      <i class='fa-solid fa-book-open-reader'></i>
      <a href="https://drive.google.com/file/d/1bHiGcv_kWN819xBrMncNBmlYZRMwf6ZK/view">
        {{$t('regulations_page.textbook_lease_agreement')}} 
      </a>
    </li>
  </ul>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group {
  margin: 0 0 60px 0;
}

.hint-title {
    padding: 20px;
    margin: 40px 0 30px 0;
    background: rgba(76, 154, 199, 0.15);
    border-radius: 5px;
    font-weight: 400;
}

.container-regulations {
  padding: 0 20%;
}
.fa-book-open-reader {
  color: rgb(172, 172, 172);
  font-size: 30px;
}
.list-group-item {
    border:none;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0 3%;
    padding: 10px 0;
}
.list-group-item a {
  text-decoration: none;
  color: #1f1f1f;
  font-weight: 600;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.list-group-item:hover .fa-book-open-reader  {
  color:#0088e1 ;
}
.list-group-item:hover a {
  color:#0088e1 ;
} 

.regulations-page-title {
   position: relative;
  top: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  color: white;
}
@media (min-width: 425px) and (max-width: 768px){
  .fa-book-open-reader {
    font-size: 25px;
  }

  .regulations-page-title{
    font-size: 30px;
  }
  .container-regulations {
  padding: 0 13%;
}
}
@media (min-width: 375px) and (max-width: 425px){
  .list-group-item a {
    font-size: 15px;
}

  .fa-book-open-reader {
    font-size: 22px;
  }
  .regulations-page-title{
    font-size: 25px;
  }
  .list-group-item {
    font-size: 16px;
  }
  .container-regulations {
  padding: 0 5%;
}
}
@media (max-width: 375px){
  .list-group-item a {
    font-size: 12px;
}

  .fa-book-open-reader {
    font-size: 20px;
  }
  .regulations-page-title{
    font-size: 20px;
  }
  .list-group-item {
    font-size: 14px;
  }
  .container-regulations {
  padding: 0 1%;
}
}

</style>