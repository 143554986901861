<template>
    <section id="why-us" class="why-us">
        <div class="container mt-5">
            <h2 class="news-title">{{ $t("news.title") }}</h2>
            <div class="row no-gutters">
                <div
                    class="col-lg-4 col-md-6 content-item"
                    v-for="jnews in news.results.slice(0, 3)"
                    :key="jnews.id"
                >
                    <h4 class="news-name">{{ jnews.title }}</h4>
                    <router-link
                        :to="{
                            name: 'news-details',
                            params: { id: jnews.id, news: jnews },
                        }"
                        ><span>{{ $t("news.read_more") }}</span></router-link
                    >
                </div>
            </div>
        </div>
        <router-link
            :to="`/${$i18n.locale}/news`"
            class="mt-5 news-button btn primary-button btn_video"
            >{{ $t("news.go_to_news_section") }}</router-link
        >
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            news: [],
            langs: ["ru", "ky"],
        };
    },
    methods: {
        getNews() {
            axios
                .get(
                    `https://kabinet.okuukitebi.edu.kg/api/news/?language=${
                        this.$i18n.locale == "ru" ? "russian" : "kyrgyz"
                    }`
                )
                .then((response) => {
                    this.news = response.data;
                });
        },
    },
    mounted() {
        this.getNews();
    },
};
</script>

<style>
.news-button {
    display: block;
    margin: 0 auto;
    width: 500px;
}
.primary-button {
    background-color: #0088e1 !important;
    color: #fff;
}
.primary-button:hover {
    background-color: #fff !important;
    color: #0088e1;
}
.news-title {
    position: relative;
    top: 40px;
    font-weight: bold;
    letter-spacing: 5px;
}
.news-name {
    font-size: 20px;
}
.why-us {
    padding: 0;
}

.why-us .row {
    overflow: hidden;
}

.why-us .content-item {
    padding: 40px;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: -1px;
}

.why-us .content-item span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: #367db7;
}

.why-us .content-item h4 {
    font-weight: 300;
    padding: 0;
    margin: 20px 0;
}

.why-us .content-item p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
}

@media (min-width: 320px) and (max-width: 425px) {
    .news-title {
        position: relative;
        top: 40px;
        font-size: 27px;
        font-weight: bold;
        letter-spacing: 5px;
    }
    .why-us.content-item {
        padding: 40px;
        padding-top: 15px !important;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin: -1px;
    }
    .btn_video {
        width: 60%;
    }
}
</style>
