<template>
  <div>
    <Banner />
    <ElectronicLibrary/>
    <ChartsHome />
    <AboutUs />
    <Patent />
    <DirectorsAbout />
    <!-- <AboutVideos /> -->
    <Infographics />
    <News />
    <Videos />
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import AboutUs from "../components/AboutUs.vue";
import Regulations from "@/components/Regulations.vue";
import News from "@/components/News.vue";
import Videos from "@/components/Videos.vue";
import Patent from "@/components/Patent.vue";
import ChartsHome from "@/components/ChartsHome.vue";
import DirectorsAbout from "@/components/DirectorsAbout.vue";
import AboutVideos from "@/components/AboutVideos.vue";
import Infographics from "@/components/Infographics.vue";
import ElectronicLibrary from "@/components/ElectronicLibrary.vue";

export default {
  name: "HomeView",
  components: {
    ElectronicLibrary,
    Banner,
    AboutUs,
    ChartsHome,
    Regulations,
    DirectorsAbout,
    // Patent,
    AboutVideos,
    Infographics,
    News,
    Videos,
  },
};
</script>
