<template>
  <div class="main-info">
    <div class="container-expand">
      <div class="main-info-left">
        <h1 class="banner-title">{{ $t("banner.title") }}</h1>
        <p class="banner-description">{{ $t("banner.description") }}</p>
        <!-- <div class="dropdown"> -->
        <!-- <button class="btn dropdown-toggle primary-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Сводные данные по аренде учебников по годам
                </button>
                
                <ul class="dropdown-menu main-info-left-links" aria-labelledby="dropdownMenuButton1">
                  <li><a href="https://docs.google.com/spreadsheets/d/1C_yrcpBfUnx6qm2kJZZrZNlRMB_a-yXA/edit?usp=sharing&ouid=104337552537160684420&rtpof=true&sd=true" class="dropdown-item" target="_blank">2018-2019</a></li>
                  <li><a href="https://docs.google.com/spreadsheets/d/1Pfgjj71cwzDvu0QCNNE7oHQF9DyITLFF/edit?usp=sharing&ouid=104337552537160684420&rtpof=true&sd=true" class="dropdown-item" target="_blank">2019-2020</a></li>
                  <li><a href="https://docs.google.com/spreadsheets/d/15HpmGBCDP2Qfc06H4NFF0WJOIr9csfgm/edit?usp=sharing&ouid=104337552537160684420&rtpof=true&sd=true" class="dropdown-item" target="_blank">2020-2021</a></li>
                  <li><a href="https://docs.google.com/spreadsheets/d/10jhI7O-C_0fzaHN7Ur9yx4FLLXgm4hEo/edit?usp=sharing&ouid=104337552537160684420&rtpof=true&sd=true" class="dropdown-item" target="_blank">2021-2022</a></li>
                  <li><a href="https://docs.google.com/spreadsheets/d/1953Lu94GRKSchqm1Rcil5IugnH3gPqUy/edit?usp=sharing&ouid=104337552537160684420&rtpof=true&sd=true" class="dropdown-item" target="_blank">2022-30.12.2022</a></li>
                </ul> -->
        <!-- </div> -->
      </div>
      <div class="main-info-right">
        <form>
          <h2 class="sign-in-title">
            {{ $t("banner.sign_in_to_library_office") }}
          </h2>
          <a
            href="https://kabinet.okuukitebi.edu.kg/sign-in"
            class="btn primary-button btn-lg sign-in"
            type="submit">
            {{ $t("banner.sign_in_btn") }}
          </a>
        </form>
        <a
          href="https://kabinet.okuukitebi.edu.kg/restore-password"
          class="forgot-password"
          >{{ $t("banner.forgot_password") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.banner-description {
  text-align: left !important;
  margin-left: 40px !important;
}
.primary-button {
  background-color: #0088e1 !important;
  color: #fff;
}
.primary-button:hover {
  background-color: #fff !important;
  color: #0088e1;
}
.sign-in-title {
  font-size: 34px;
  font-weight: bold;
  margin-top: 20rem;
}
.banner-title {
  font-size: 42px;
  font-weight: 600;
}
.sign-in {
  position: relative;
  left: 20px;
  width: 300px;
  margin: 20px;
  font-weight: 600;
}
.rented-data {
  position: absolute;
  left: 185px;
}
.main-info {
  background: linear-gradient(to left, transparent, rgb(73, 61, 61, 0.8)),
    url("../assets/main-bg.jpg") no-repeat center center;
  background-size: cover;
}
.main-info .container-expand {
  display: flex;
  padding: 0;
}

.main-info-left {
  width: 76.7777%;
  padding: 100px 145px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.main-info-left h1 {
  font-size: 64px;
  color: #ffffff;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Myriad Pro Bold", sans-serif;
  margin: 0 0 50px;
}
.main-info-left p {
  font-size: 28px;
  color: #ffffff;
  line-height: 42px;
  margin: 0 0 50px;
  text-align: center;
}
.main-info-left .main-info-link {
  width: 220px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  font-family: "Myriad Pro Bold", sans-serif;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}
.main-info-left .apply {
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  margin-right: 30px;
}
.main-info-left .apply:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.main-info-left .check {
  border: 3px solid #ffffff;
}
.main-info-left .check:hover {
  text-decoration: underline;
}
.main-info-left .main-info-left-map {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 75px;
  line-height: 75px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
.main-info-left .main-info-left-map a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-info-left .main-info-left-map a i {
  font-size: 36px;
  text-decoration: none;
  margin-right: 15px;
}
.main-info-left .main-info-left-map a:hover {
  text-decoration: underline;
}

.main-info-right {
  width: 33.3333%;
  background-color: rgb(124 135 152 / 83%);
  color: rgba(52, 131, 255, 0.5);
  padding: 110px 100px 100px;
  display: flex;
  flex-direction: column;
}
.main-info-right h2 {
  font-size: 32px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
.main-info-right button {
  width: 100%;
  height: 55px;
  line-height: 55px;
  border: none;
  background: linear-gradient(to bottom, #63b8d8 0%, #357cb6 100%);
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  font-family: "Myriad Pro Bold", sans-serif;
  padding: 0;
  margin-bottom: 25px;
  transition: 0.5s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
.main-info-right button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.main-info-right .forgot-password {
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
}
.main-info-right input {
  width: 100%;
  border: 1px solid #888888;
  height: 55px;
  line-height: 55px;
  border-radius: 4px;
  padding: 0 53px;
  color: #888888;
  outline: none;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}
.input-container i {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  opacity: 0.5;
}

.sign-up {
  font-family: "Myriad Pro Bold", sans-serif;
  color: #ffffff;
  display: inline-block;
  width: 100%;
  height: 55px;
  line-height: 55px;
  overflow: hidden;
  background: linear-gradient(to bottom, #bad674 0%, #80b23e 100%);
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  transition: 0.5s;
}
.sign-up:hover {
  background: linear-gradient(to bottom, #bae582 0%, #51b23e 100%);
}
.or {
  width: 100%;
  text-align: center;
  opacity: 0.5;
  color: #ffffff;
  margin-bottom: 30px;
}
@media screen and (max-width: 1600px) {
  .main-info-left {
    padding: 65px 85px 0;
  }

  .main-info-left h1 {
    line-height: 1.3;
    font-size: 45px;
  }

  .main-info-left p {
    font-size: 24px;
    line-height: 32px;
  }

  .main-info-right {
    padding: 55px 55px 65px;
  }

  .about-us-text {
    padding: 65px 0 70px;
    max-width: 770px;
  }

  .how-it-works .container-expand {
    padding: 75px 15px;
  }

  .how-it-works .container-expand .how-it-works-text {
    width: 66.6666%;
  }

  .how-it-works:before {
    left: calc(25% + 20px);
  }

  .how-it-works:after {
    right: calc(25% + 20px);
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 60px;
  }

  .write-us .container-expand .write-us-inner {
    width: 66.6666%;
  }

  .write-us .container-expand form {
    width: 50%;
  }

  .write-us .container-expand {
    padding: 90px 15px 35px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    width: 50%;
  }

  .regulatory .container-expand .regulatory-items {
    padding: 0 40px;
  }

  .regulatory .container-expand .regulatory-link {
    margin-bottom: 0;
  }

  .footer-nav-container .container-expand .footer-nav {
    padding: 0 40px;
  }

  .footer-contacts
    .container-expand
    .footer-contacts-items
    .footer-contacts-item {
    padding: 0 60px 0;
  }

  .footer-bottom .container-expand .footer-social {
    width: 200px;
    margin: 0 60px;
  }

  .faq-info .faq-left {
    padding: 70px 30px 10px 15px;
  }

  .faq-info .faq-right .write-us-inner {
    width: 75%;
  }

  .faq-info .faq-right form .write-us-inputs {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
    margin-bottom: 20px;
  }

  .faq-info .faq-right p {
    margin-bottom: 35px;
  }

  .docs-block .container-expand {
    padding: 40px 15px 55px;
  }

  .docs-block .container-expand .hint-title,
  .docs-block .container-expand .docs-items {
    width: 66.6666%;
  }

  .instructions-inner-block-container .instructions-inner-right {
    padding: 65px 90px 0;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    span {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-video {
    width: 470px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-paragraphs {
    margin-bottom: 30px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns {
    padding: 30px 0 40px;
  }

  .statistics-items {
    flex-wrap: wrap;
  }

  .statistics-items .statistics-item {
    width: 100%;
    padding: 20px 50px;
    border-bottom: 1px solid #ffffff;
  }

  .statistics-items .statistics-item:last-child {
    border-bottom: none;
  }

  .statistics-items .statistics-item .statistics-item-title {
    flex-direction: row;
    justify-content: space-between;
  }

  .statistics-items .statistics-item .statistics-item-title h5,
  .statistics-items .statistics-item .statistics-item-title span {
    margin-bottom: 0;
  }

  .statistics-items .statistics-item .statistics-item-text {
    flex-direction: row;
    padding: 20px 75px;
  }

  .statistics-items
    .statistics-item
    .statistics-item-text
    .statistics-item-text-item {
    justify-content: flex-start;
    width: 33.3333%;
  }

  .statistics-items
    .statistics-item
    .statistics-item-text
    .statistics-item-text-item
    p {
    margin-right: 15px;
  }

  .statistics-block .container-expand .hint-title {
    margin-bottom: 40px;
  }

  .map-space {
    height: 540px;
  }

  .map-text-title {
    padding: 25px 16.6666%;
  }

  .slider {
    height: 280px;
    padding-top: 265px;
  }

  .map-text-main-info-text .map-text-main-info-text-item {
    font-size: 16px;
  }

  .map-text-link {
    width: 270px;
  }

  .recovery-block .container-expand {
    padding: 50px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 40px;
  }

  .recovery-form-block button {
    width: 100%;
  }

  .login-block .recovery-input-container-first {
    margin-bottom: 20px;
  }

  .login-block .recovery-list a {
    width: 66.6666%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .reg-step-map-title {
    padding: 30px 0;
  }

  .reg-step-btn {
    padding: 40px 0 65px;
  }

  .reg-step-1-left {
    padding: 65px 45px;
  }

  .datepicker-container {
    width: 100%;
  }

  .reg-step-form-radio-item {
    margin-bottom: 30px;
  }

  .pass-serial,
  .pass-number {
    width: 40%;
  }

  .reg-step-2-dropzones .reg-step-form-item {
    width: 100%;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone {
    height: 290px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone .dz-image {
    height: 255px;
  }

  .reg-step-2-dropzones .reg-step-form-item-text {
    margin-bottom: 0;
    width: 60%;
  }

  .reg-step-2-dropzones .reg-step-form-item .issuing-authority-container {
    width: 75% !important;
  }

  .reg-final-block {
    padding: 30px 0 35px;
  }

  .reg-final-like {
    width: calc(33.3333% + 60px);
    padding: 0 60px;
  }

  .reg-final-like-text h3 {
    width: calc(100% + 60px);
    margin-left: -30px;
  }

  .reg-final-personal-area {
    padding: 30px 30px 0;
  }

  .reg-final-personal-area > a {
    width: 100%;
  }

  .check-block-container .hint-title {
    width: 66.6666%;
    margin-bottom: 20px;
  }

  .check-block-item-bottom {
    width: 66.6666%;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 23%;
  }

  .check-block-item-bottom .reg-step-form-item-width {
    width: 48%;
  }

  .check-block-item-bottom button {
    width: 23%;
  }

  .check-results-username {
    padding: 40px 0;
  }

  .check-results-btn a {
    width: 25%;
  }

  .my-apps-left {
    width: calc(25% + 30px);
  }

  .my-applications-title {
    width: calc(75% - 30px);
  }

  .my-apps-right {
    width: calc(75% - 30px);
  }

  .my-applications-title {
    padding: 40px 30px;
  }

  .my-apps-card {
    padding: 20px 0;
  }

  .my-apps-right {
    padding: 30px 15px 30px 30px;
  }

  .my-apps-card {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .my-apps-card-text {
    width: 50%;
    padding: 0 20px;
  }

  .my-apps-card-main-status {
    width: 100%;
  }

  .my-apps-card-links {
    width: 33.3333%;
    margin: 0 auto;
  }

  .my-apps-card-main-status-link {
    margin-bottom: 20px;
  }

  .my-apps-right-details {
    padding: 40px 15px 40px 30px !important;
  }

  .my-apps-detail-tabs-content {
    padding: 40px 0 60px;
  }

  .my-apps-details-status-info {
    margin-bottom: 40px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 16.666%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-img img {
    width: 100%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn a {
    width: 60%;
  }

  .reg-step-5-item-img-details-2 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
  .reg-step-5-item-img-details-2 img {
    width: 66.6666% !important;
    margin-bottom: 10px !important;
  }

  .my-apps-detail-tabs-content-details-3 .my-apps-details-3-school-name {
    padding: 18px 11.1111%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info {
    padding: 25px 11.1111% 10px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item p {
    width: 60%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn a {
    width: 33.3333%;
  }
  .my-apps-detail-tabs-content-details-3 .padding-left,
  .my-apps-detail-tabs-content-details-3 .padding-right {
    width: 11.1111%;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    margin: 0 11.1111% 15px;
  }

  .my-apps-details-3-info {
    margin: 0 11.1111%;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 55.5555% !important;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 55.5555%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 22.2222%;
    width: 22.2222%;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    width: 100% !important;
    padding: 20px 22.5% 20px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 40px 22.5%;
  }

  .reg-spep-2-btn-add-step-2 a {
    width: 48% !important;
    margin: 0 !important;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0;
  }
  .my-app-add-step-final-block .reg-final-like-text h3 {
    font-size: 18px;
  }
  .my-app-add-step-final-block .reg-final-like-text span {
    font-size: 24px;
  }
  .my-app-add-step-final-block .reg-final-personal-area {
    width: 55.5555%;
    padding: 30px 0 0;
  }

  .my-apps-right-data .reg-step-5-item-img-details-2 img {
    margin: 0 !important;
    width: 65% !important;
  }
  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 22.2222%;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: 55.5555%;
  }

  .dir-pend-check-title {
    width: 75%;
  }

  .dir-pend-check-links a {
    padding: 12px 20px;
  }

  .director-pending-checks-list .my-apps-right {
    padding: 35px;
  }

  .dit-pend-check-top {
    padding: 40px 40px 20px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 22.2222%;
  }

  .director-pending-checks-request .reg-step-btn,
  .director-pending-checks-request .dir-req-prev-next-btns {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accept-modal .accept-modal-block {
    width: 41.6666%;
  }

  .file-loaded .file-loaded-block {
    width: 41.6666%;
  }

  .dir-school-main .dir-school-edit a {
    width: 60%;
  }
}
@media screen and (max-width: 1200px) {
  .header-sign-in,
  .main-nav-links {
    display: none;
  }

  .main-nav-links-mobile {
    display: flex;
  }

  .header-sign-in-link {
    font-size: 18px;
    color: #888888;
    border-right: none;
  }

  .main-nav-links-mobile i {
    font-size: 36px;
  }

  .main-nav-logo div {
    width: 190px;
    height: 60px;
  }

  .main-nav .container-expand {
    padding: 10px 15px;
  }

  #navBarBtn {
    background: transparent;
    border: none;
    color: #888888;
  }

  .main-info .container-expand {
    padding: 0;
  }

  .main-info-right {
    display: none;
  }

  .main-info-left {
    width: 100%;
    padding: 65px 65px 105px;
  }

  .main-info-left .main-info-left-map a i,
  .regulatory .container-expand .regulatory-items .regulatory-item a i {
    font-size: 24px;
  }

  .main-info-left p {
    font-size: 18px;
    margin: 0 0 40px;
    line-height: 24px;
  }

  .main-info-left h1 {
    text-align: center;
    width: 100%;
    padding: 0 80px;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  .main-info-left .main-info-left-map {
    height: 65px;
    line-height: 65px;
  }

  .about-us-text {
    max-width: none;
    width: 100%;
    padding: 55px 0 50px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 35px;
  }

  .about-us-text p {
    font-size: 16px;
    line-height: 21px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-1 {
    background-position: -282px -5px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-2 {
    background-position: -282px -135px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-3 {
    background-position: -482px -195px;
  }

  .how-it-works .container-expand .how-it-works-text p {
    margin-bottom: 30px;
    line-height: 24px;
  }

  .how-it-works:before,
  .how-it-works:after {
    top: 39%;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 15px;
  }

  .how-it-works .container-expand {
    padding: 45px 0 50px;
  }

  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 45px;
  }

  .how-it-works .container-expand .how-it-works-about-link {
    margin-bottom: 0;
    width: 33.3333%;
  }

  .write-us .container-expand {
    padding: 50px 15px 50px;
  }

  .write-us .container-expand form {
    width: 100%;
  }

  .write-us .container-expand p {
    font-size: 16px;
  }

  .write-us .container-expand form button {
    margin-bottom: 0;
  }

  .regulatory .container-expand {
    padding: 50px 0 45px;
  }

  .regulatory .container-expand .regulatory-items {
    padding: 0;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    padding: 0 15px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item a {
    padding-left: 35px;
  }

  .footer-nav-container .container-expand {
    padding: 35px 15px 20px;
  }

  .footer-nav-container .container-expand .footer-nav ul {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .footer-nav-container .container-expand .footer-nav {
    padding: 0;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    margin-bottom: 25px;
    padding: 0 40px;
    width: 33.3333%;
  }

  .main-nav-link-1 {
    order: 1;
  }

  .main-nav-link-2 {
    order: 4;
  }

  .main-nav-link-3 {
    order: 2;
  }

  .main-nav-link-4 {
    display: none;
  }

  .main-nav-link-4-B {
    display: inline-block;
    order: 5;
  }

  .main-nav-link-5 {
    order: 3;
  }

  .main-nav-link-6 {
    order: 6;
  }

  .footer-contacts
    .container-expand
    .footer-contacts-items
    .footer-contacts-item {
    padding: 0 40px;
    width: 50%;
  }

  .footer-bottom .container-expand {
    padding: 0;
    flex-wrap: wrap;
  }

  .footer-bottom .container-expand .footer-social {
    padding: 20px 16.6666%;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #888888;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 48px;
  }

  .footer-bottom .container-expand span {
    padding: 20px 15px;
  }

  .footer-bottom .container-expand p {
    padding: 20px 15px;
  }

  .faq-title {
    padding: 35px 0;
  }

  .faq-title h2 {
    font-size: 24px;
  }

  .faq-info {
    flex-wrap: wrap;
  }

  .faq-info .faq-left {
    width: 100%;
    padding: 30px 16.6666% 55px;
  }

  .faq-info .faq-right {
    width: 100%;
  }

  .faq-info .faq-right {
    padding: 50px 0;
  }

  .faq-info .faq-right p {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 33.3333%;
    margin: 0 auto;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 48%;
  }

  .docs-block .container-expand {
    padding: 30px 15px 45px;
  }

  .docs-block .container-expand .docs-items .docs-item a i {
    font-size: 24px;
  }

  .docs-block .container-expand .docs-items .docs-item a {
    padding-left: 50px;
  }

  .docs-block .container-expand .hint-title {
    margin-bottom: 35px;
  }

  .instructions-block .container-expand {
    padding: 40px 50px 50px;
  }

  .instructions-block .container-expand .instructions-items {
    font-size: 16px;
  }

  .instructions-block
    .container-expand
    .instructions-items
    .instructions-item-links
    a
    i {
    font-size: 32px;
  }

  .instructions-block
    .container-expand
    .instructions-items
    .instructions-item-links
    a {
    margin-bottom: 30px;
  }

  .instructions-block .container-expand .instructions-items,
  .instructions-block .container-expand .hint-title {
    width: 100%;
  }

  .instructions-inner-block-container .instructions-inner-left {
    display: none;
  }

  .instructions-inner-block-container .instructions-inner-right {
    width: 100%;
    padding: 35px 16.6666% 0;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-video {
    width: 100%;
    margin: 0 auto 30px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-paragraphs {
    font-size: 16px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-text-img {
    justify-content: space-between;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-text-img
    .instructions-inner-right-img-container {
    width: 48%;
    min-width: 48%;
    margin-right: 0;
  }

  .instructions-inner-right-text-container {
    width: 48%;
    font-size: 16px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text {
    padding-bottom: 30px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns {
    padding: 30px 0;
  }

  .statistics-block .container-expand {
    padding: 40px 0 55px;
  }

  .statistics-block .container-expand .hint-title {
    margin: 0 15px 40px;
  }

  .statistics-block .container-expand .panel-title {
    padding-left: 15px;
  }

  .statistics-items .statistics-item {
    padding: 15px 40px;
  }

  .statistics-items .statistics-item .statistics-item-text {
    padding: 15px 70px 0;
  }

  .map-space {
    height: 850px;
  }

  .map-space .map-search {
    width: 66.6666%;
  }

  .map-text-title {
    padding: 20px 15px;
  }

  .map-text-title h3 {
    font-size: 18px;
  }

  .map-text-link {
    margin: 20px auto 20px;
    width: 33.3333%;
  }

  .map-text-statistics {
    padding: 0;
    margin-bottom: 40px;
  }

  .map-text-main-info {
    padding: 0 15px;
  }

  .slider {
    height: 260px;
    padding-top: 245px;
  }

  .map-text-main-info-text .map-text-main-info-text-item {
    margin-bottom: 20px;
  }

  .map-text-main-info-text .map-text-main-info-text-item:last-child {
    margin-bottom: 0;
  }

  .map-text-contacts {
    padding: 25px 15px;
  }

  .recovery-block .container-expand {
    padding: 40px 0 0;
  }

  .recovery-notice {
    padding: 25px 0;
  }

  .recovery-info,
  .recovery-notice p {
    width: 66.6666%;
  }

  .recovery-notice p {
    font-size: 16px;
  }

  .recovery-form-block {
    width: 100%;
  }

  .recovery-label {
    font-size: 16px;
  }

  .recovery-form-block button {
    margin-bottom: 25px;
  }

  .forgot-password {
    margin-bottom: 30px;
  }

  .login-block .recovery-list {
    width: 66.6666%;
    font-size: 16px;
  }

  .login-block .recovery-list > span,
  .login-block .recovery-list li {
    margin-bottom: 15px;
  }

  .login-block .recovery-list a {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reg-step-1-left {
    display: none;
  }

  .reg-step-1-left {
    padding: 45px 0;
  }

  .reg-step-1-right-title span {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .reg-step-1-right-title h3 {
    font-size: 24px;
  }

  .reg-step-1-right-title {
    margin-bottom: 20px;
  }

  .reg-step-1-form-container {
    width: 66.6666%;
  }

  .reg-step-1-right .hint-title {
    margin-bottom: 15px;
  }

  .reg-step-form-item-double {
    flex-direction: column;
  }

  .select-1 {
    width: 100%;
  }

  .select-2 {
    width: 50%;
    margin-left: 0;
  }

  .reg-step-map-free h4 {
    font-size: 18px;
  }

  .reg-step-btn a {
    width: 33.3333%;
    margin-right: 16.6666%;
  }

  .reg-step-btn {
    padding: 35px 0 55px;
  }

  .dropzone {
    height: 245px;
  }

  .dz-image {
    height: 210px;
    width: 300px;
  }

  .reg-step-2-btn a {
    margin: 0;
    width: 48%;
  }

  .reg-step-2-btn {
    padding: 30px 16.6666%;
    display: flex;
    justify-content: space-between;
  }

  .reg-step-form-radio-item {
    margin-bottom: 25px;
  }

  .pass-serial,
  .pass-number {
    width: 48%;
    margin-right: 0;
  }

  .reg-step-form-passport-info {
    justify-content: space-between;
  }

  .reg-step-3-password-container {
    width: 100%;
    margin-bottom: 40px;
  }

  .reg-step-3-password-container-inner {
    width: 66.6666%;
  }

  .reg-step-4-form-container {
    padding-bottom: 20px;
  }

  .dropzone-step-4 {
    height: 245px;
  }
  .dropzone-step-4 .dz-image {
    height: 215px;
  }

  .reg-step-5-items .hint-title {
    margin-bottom: 30px;
  }

  .reg-step-5-item {
    padding: 0 16.6666% 35px;
  }

  .reg-step-5-items .hint-title {
    margin: 0 16.6666% 30px;
  }

  .reg-step-5-item-line {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .reg-step-5-item-line p,
  .reg-step-5-item-line span,
  .reg-step-5-item-img,
  .reg-step-5-item-btn a {
    width: 100%;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img {
    justify-content: space-between;
    display: flex;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img img {
    width: 48%;
    height: 100%;
  }

  .reg-final-like {
    width: 66.6666%;
    padding: 0;
  }

  .reg-final-like-text h3 {
    margin: 0;
    width: 100%;
    margin-bottom: 35px;
  }

  .reg-final-like-text p {
    margin-bottom: 15px;
  }

  .reg-final-personal-area {
    width: 66.6666%;
    padding: 30px 0 0;
  }

  .reg-final-personal-area p {
    margin-bottom: 10px;
  }

  .check-block-container {
    padding: 40px 0 50px;
  }

  .check-block-container .hint-title {
    padding: 10px;
  }

  .check-block-item-elements-1 {
    width: 66.6666%;
    flex-wrap: wrap;
  }

  .check-block-item-elements-1 .reg-step-form-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .check-block-item-elements-1 button {
    width: 100%;
  }

  .check-block-item-elements {
    flex-wrap: wrap;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 48%;
    margin-bottom: 25px;
  }

  .check-block-item-bottom button {
    width: 100%;
  }

  .check-results-block .faq-title h2 {
    letter-spacing: 6px;
  }

  .check-results-username {
    padding: 30px 0;
  }

  .check-results-username h3 {
    font-size: 24px;
  }

  .check-results-main-info h4 {
    font-size: 18px;
  }

  .check-results-main-info i {
    margin-bottom: 15px;
  }

  .check-results-main-info span {
    font-size: 16px;
  }

  .check-results-notice-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .check-results-notice h4 {
    margin: 0 0 15px;
    font-size: 16px;
  }

  .check-results-notice p {
    font-size: 16px;
  }

  .check-results-child-info-item {
    width: 100%;
  }

  .check-results-child-info-item p {
    width: 50%;
  }

  .check-results-child-info {
    padding: 20px 16.6666% 10px;
  }

  .padding-left,
  .padding-right {
    display: none;
  }

  .check-results-table h4 {
    margin: 0 15px 15px;
  }

  .check-results-btn a {
    width: 33.3333%;
  }

  .my-applications-title {
    display: none;
  }

  .my-applications-title-hidden {
    display: block;
    width: 100%;
    padding: 20px 45px;
    margin-bottom: 10px;
  }
  .my-applications-title-hidden h2 {
    font-size: 24px;
  }

  .header-my-apps .main-nav .container-expand {
    padding: 10px 15px;
  }

  .main-nav-logo div {
    width: 190px;
  }

  .header-my-apps .main-nav-logo {
    margin: 0;
  }

  .my-apps-left {
    width: calc(33.3333% + 15px);
  }

  .my-apps-right {
    width: calc(76.6666% - 15px);
    padding: 0;
  }

  .my-apps-card {
    margin: 0 15px;
  }
  .my-apps-card:last-child {
    margin-bottom: 15px;
  }

  .my-apps-card-text {
    width: 100%;
  }

  .my-apps-card-links {
    width: 100%;
    padding: 0 15px;
  }

  .my-apps-right-details {
    padding: 0 !important;
  }

  .my-apps-detail-tabs-links a {
    width: 33.3333%;
  }

  .my-apps-detail-tabs-content {
    padding: 20px 0 35px;
  }

  .my-apps-details-status {
    margin-bottom: 15px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line {
    padding: 0 40px;
  }

  .my-apps-details-date {
    padding: 15px 40px !important;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0 40px;
  }
  .my-apps-detail-tabs-content .reg-step-5-item-btn a {
    width: 100%;
  }

  .my-apps-details-status,
  .my-apps-details-status-info {
    font-size: 16px;
  }

  .my-apps-detail-tabs-content-details-3 .my-apps-details-3-school-name {
    padding: 18px 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info {
    padding: 25px 15px 10px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item p {
    width: 80%;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn {
    padding: 30px 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-btn a {
    width: 100%;
  }
  .my-apps-detail-tabs-content-details-3 .padding-left,
  .my-apps-detail-tabs-content-details-3 .padding-right {
    width: 15px;
  }
  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    width: 50% !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    margin: 0 15px 15px;
  }

  .my-apps-details-3-info {
    margin: 0 15px;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 80% !important;
  }

  .my-app-add-step-1-block .reg-step-1-right {
    padding: 20px 0 0;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 80%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 10%;
    width: 40%;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    padding: 20px 10% 20px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 30px 10% !important;
  }

  .my-app-add-step-3-block .reg-step-5-item-img img {
    width: 75%;
  }

  .my-app-add-step-3-block .reg-step-5-item-line {
    padding: 0;
  }

  .my-app-add-step-final-block .reg-final-personal-area {
    width: 100%;
    padding: 20px 15px 0;
  }

  .reg-final-block {
    padding: 0 0 20px;
  }

  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 30px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: calc(100% - 60px);
  }

  .director-pending-checks-list .my-apps-left {
    width: 33.3333%;
    min-height: 95vh;
  }

  .director-pending-checks-list .my-apps-right {
    width: 66.6666%;
    padding: 0;
  }

  .dir-pend-check-links a {
    padding: 5px 10px 5px 15px;
  }

  .dit-pend-check-top {
    padding: 30px 25px;
  }

  .dir-pend-nav .dir-pend-search-input {
    width: 70%;
    margin: 0;
  }

  .dir-pend-nav .dir-pend-search-select {
    width: 25%;
    margin: 0;
  }

  .dir-page-links-inner {
    width: 85%;
  }

  .dir-pend-check-req-title h3 {
    font-size: 24px;
    padding: 0 25px;
    margin-bottom: 15px;
  }

  .director-pending-checks-request .my-apps-detail-tabs-links a {
    width: 23%;
  }

  .director-pending-checks-request
    .my-apps-detail-tabs-content
    .reg-step-5-item-line {
    padding: 0 25px;
  }

  .director-pending-checks-request .reg-step-btn,
  .director-pending-checks-request .dir-req-prev-next-btns {
    padding: 25px;
  }

  .accept-modal .accept-modal-block {
    width: 66.6666%;
  }

  .accept-modal-block-accepted {
    width: 58.3333% !important;
  }

  .file-loaded .file-loaded-block {
    width: 66.6666%;
  }

  .dir-pend-order-add-text,
  .dir-pend-order-add-buttons {
    padding: 0 15px !important;
  }

  .dir-rej-req-reasons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .map-text-main-info {
    flex-wrap: wrap;
  }

  .map-text-main-info-slider {
    width: 100%;
  }

  .map-text-main-info-text {
    width: 100%;
  }

  .dir-school-main .dir-pend-check-block {
    padding: 20px 15px;
  }

  .dir-school-main .map-text-contacts .map-text-contacts-phone {
    justify-content: flex-start;
  }

  .dir-school-main .map-text-contacts .map-text-contacts-phone b {
    margin-right: 10px;
  }

  .dir-school-main .dir-school-edit a {
    width: 100%;
  }

  .map-text-main-info {
    margin-bottom: 20px;
  }

  .slider input[name="slide_switch"] ~ img {
    transform: scale(1);
  }

  .dir-school-main .reg-step-2-btn {
    width: 100%;
    padding: 30px 0;
  }

  .reg-step-1-form-container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .header-sign-in-link span {
    display: none;
  }

  .header-sign-in-link {
    padding-right: 20px;
  }

  .header-sign-in-link i {
    margin-right: 0;
  }

  .main-nav-links-navbar-mobile ul li {
    width: 100%;
  }

  .main-info-left {
    padding: 40px 0 100px;
  }

  .main-info-left-links {
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .main-info-left .apply {
    margin-right: 0;
  }

  .main-info-left .main-info-link {
    width: 48%;
  }

  .main-info-left h1 {
    padding: 0 30px;
    font-size: 48px;
  }

  .main-info-left p {
    padding: 0 30px;
  }

  .about-us-text {
    padding: 45px 0 50px;
  }

  .how-it-works .container-expand .how-it-works-text {
    width: 100%;
    padding: 0 15px;
  }

  .how-it-works .container-expand .how-it-works-text p {
    font-size: 16px;
  }

  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    margin: 0 10px 0 0;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-1 {
    background-position: -5px -135px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-2 {
    background-position: -5px -265px;
  }

  .how-it-works
    .container-expand
    .how-it-works-cards
    .how-it-works-card
    .how-it-works-card-icon-3 {
    background-position: -412px -195px;
  }

  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    text-align: left;
  }

  .how-it-works:before {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 39%;
    background: url("../assets/dot.png") repeat-y;
  }

  .how-it-works:after {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 56%;
    background: url("../assets/dot.png") repeat-y;
  }

  .how-it-works .container-expand .how-it-works-about-link {
    width: 290px;
  }

  .write-us .container-expand .write-us-inner {
    width: 100%;
  }

  .write-us .container-expand form textarea {
    margin-bottom: 20px;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    width: 100%;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    margin-bottom: 20px;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    width: 50%;
    padding: 0 30px;
  }

  .main-nav-link-4-B {
    order: 2;
  }

  .main-nav-link-2 {
    order: 3;
  }

  .main-nav-link-5 {
    order: 4;
  }

  .main-nav-link-3 {
    order: 5;
  }

  .footer-contacts
    .container-expand
    .footer-contacts-items
    .footer-contacts-item {
    width: 100%;
    padding: 0 30px;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 36px;
  }

  .footer-bottom .container-expand span {
    font-size: 12px;
  }

  .footer-bottom .container-expand p {
    font-size: 12px;
  }

  .faq-title {
    padding: 30px 0;
  }

  .faq-info .faq-left {
    padding: 20px 15px 30px;
  }

  .hint-title {
    margin: 0 0 30px;
    padding: 10px;
  }

  .panel-body {
    padding: 10px;
  }

  .faq-info .faq-right {
    padding: 45px 15px;
  }

  .faq-info .faq-right .write-us-inner {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 50%;
  }

  .docs-block .container-expand {
    padding: 20px 15px 35px;
  }

  .docs-block .container-expand .hint-title,
  .docs-block .container-expand .docs-items {
    width: 100%;
  }

  .docs-block .container-expand .docs-items .docs-item a {
    padding-left: 30px;
  }

  .docs-block .container-expand .docs-items .docs-item {
    margin-bottom: 20px;
  }

  .instructions-block
    .container-expand
    .instructions-items
    .instructions-item-links
    a {
    width: 100%;
  }

  .instructions-block
    .container-expand
    .instructions-items
    .instructions-item-links {
    padding: 0 0 0 20px;
  }

  .instructions-block .container-expand {
    padding: 20px 15px 15px;
  }

  .instructions-block .container-expand .instructions-items h5 {
    margin: 25px 0;
  }

  .instructions-inner-block-container .instructions-inner-right {
    padding: 30px 15px 0;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    h3 {
    font-size: 24px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-video {
    margin: 0 0 30px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns
    .all-instructions {
    width: 165px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns {
    padding: 35px 0 40px;
  }

  .statistics-block .container-expand {
    padding: 20px 0 30px;
  }

  .statistics-items .statistics-item .statistics-item-text {
    flex-direction: column;
    padding: 15px 0 0;
  }

  .statistics-items
    .statistics-item
    .statistics-item-text
    .statistics-item-text-item {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .statistics-items
    .statistics-item
    .statistics-item-text
    .statistics-item-text-item:last-child {
    margin-bottom: 0;
  }

  .map-space {
    height: 640px;
  }

  .map-space .map-search {
    width: calc(100% - 30px);
  }

  .map-text-title {
    flex-wrap: wrap;
  }

  .map-text-title h3 {
    width: 100%;
  }

  .map-text-link {
    width: 50%;
    margin: 30px auto;
  }

  .map-text-main-info {
    flex-wrap: wrap;
  }

  .map-text-main-info-slider,
  .map-text-main-info-text {
    width: 100%;
  }

  .map-text-main-info-slider {
    margin-bottom: 160px;
    border-bottom: 1px solid #d0d0d0;
  }

  .slider {
    height: 335px;
    padding-top: 320px;
  }

  .map-text-contacts {
    flex-wrap: wrap;
  }

  .map-text-contacts span {
    width: 100%;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: row;
  }

  .map-text-contacts .map-text-contacts-phone b {
    margin-right: 25px;
  }

  .map-text-contacts span {
    margin-bottom: 20px;
  }

  .recovery-block .container-expand {
    padding: 30px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 30px;
  }

  .recovery-info,
  .recovery-notice p {
    width: 100%;
    padding: 0 15px;
  }

  .login-block .recovery-list {
    width: 100%;
    padding: 0 15px;
  }

  .login-block .recovery-list > span,
  .login-block .recovery-list li {
    margin-bottom: 10px;
  }

  .login-block .recovery-input-container-first {
    margin-bottom: 15px;
  }

  .reg-step-1-right {
    padding: 30px 0 0;
  }

  .reg-step-1-form-container {
    width: 100%;
    padding: 0 15px;
  }

  .reg-step-1-right-title span {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .reg-step-1-right-title h3 {
    font-size: 18px;
  }

  .reg-step-map-title {
    padding: 20px 0;
  }

  .reg-step-map-free h4 {
    font-size: 24px;
  }

  .reg-step-map-items {
    height: 170px;
  }

  .reg-step-btn {
    padding: 30px 0;
  }

  .reg-step-btn a {
    width: 50%;
    margin-right: 15px;
  }

  .dropzone {
    height: 330px;
  }

  .dz-image {
    height: 300px;
    width: 420px;
  }

  .reg-step-2-btn a {
    margin: 0 !important;
    width: 48%;
  }

  .reg-step-2-btn {
    padding: 30px 15px;
  }

  .reg-step-3-password-container-inner {
    width: 100%;
  }

  .reg-step-3-password-container {
    padding: 20px 15px 45px;
    margin-bottom: 30px;
  }

  .dropzone-step-4 {
    height: 235px;
  }
  .dropzone-step-4 .dz-image {
    height: 205px;
  }

  .reg-step-5-items .hint-title {
    margin: 0 15px 30px;
  }

  .reg-step-5-item {
    padding: 0 15px 35px;
  }

  .reg-step-5-item-line {
    padding: 0 30px;
    margin-bottom: 10px;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img {
    flex-wrap: wrap;
  }

  .reg-step-5-item-line-pass .reg-step-5-item-img img {
    width: 100%;
  }

  .reg-step-5-item-phones {
    width: 100%;
  }

  .reg-final-like-img {
    width: 105px;
    height: 85px;
  }

  .reg-final-like {
    width: 100%;
    padding: 0 15px;
  }

  .reg-final-like-text span {
    margin-bottom: 25px;
  }

  .reg-final-personal-area {
    padding: 30px 15px 0;
    width: 100%;
  }

  .reg-final-like-text h3,
  .reg-final-like-text span {
    font-size: 24px;
  }

  .check-block-container {
    padding: 30px 0 35px;
  }

  .check-block-container .hint-title {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }

  .check-block-item-elements-1 {
    width: calc(100% - 30px);
    margin-bottom: 30px;
  }

  .check-block-item-bottom {
    width: calc(100% - 30px);
    padding-top: 30px;
  }

  .check-results-username {
    padding: 25px 0;
  }

  .check-results-main-info {
    padding: 15px 0 25px;
  }

  .check-results-main-info i {
    margin-bottom: 10px;
  }

  .check-results-notice {
    padding: 25px 15px 30px;
  }

  .check-results-child-info {
    padding: 20px 15px 10px;
  }

  .check-results-btn {
    padding: 30px 15px;
  }

  .check-results-btn a {
    width: 100%;
  }

  .my-apps-block {
    flex-wrap: wrap;
  }

  .my-apps-left {
    width: 100%;
  }

  .my-apps-right {
    width: 100%;
  }

  .my-apps-left {
    min-height: auto;
    padding: 15px;
  }

  .my-apps-left-links {
    justify-content: space-between;
  }

  .my-apps-left-links a {
    width: 49%;
    padding: 10px 15px;
    white-space: nowrap;
  }

  .my-applications-title-hidden {
    padding: 20px 15px;
    margin: 0;
  }

  .my-applications-title-hidden h2 {
    font-size: 18px;
  }

  .my-apps-card,
  .my-apps-card:last-child {
    margin: 0;
  }

  .my-applications-title-hidden-details {
    display: flex;
  }
  .my-applications-title-hidden-details h2 {
    width: 50%;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line,
  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0 15px;
  }

  .my-apps-details-date {
    padding: 10px 15px !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    width: 100% !important;
    padding: 0 20%;
  }

  .my-apps-changing-block {
    padding: 20px 0 30px;
  }

  .my-apps-changing-block .reg-step-1-form-container,
  .my-apps-changing-block .reg-step-2-btn,
  .my-apps-changing-block .my-apps-details-3-info {
    width: 100% !important;
  }

  .my-apps-changing-block .reg-step-2-btn {
    padding: 10px 15px 30px;
  }

  .my-apps-changing-block .my-apps-details-3-info {
    width: calc(100% - 30px) !important;
    margin: 0 15px;
  }

  .my-app-add-step-1-block .reg-step-1-form-container {
    width: 100%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    margin-right: 15px;
  }

  .my-app-add-step-1-block .reg-step-4-form-container {
    padding: 20px 15px;
  }

  .reg-spep-2-btn-add-step-2 {
    padding: 20px 15px !important;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0 15%;
  }

  .my-apps-right-data .my-apps-detail-tabs-content .reg-step-5-item {
    padding: 0 15px;
  }

  .my-apps-detail-tabs-content .reg-step-5-item-line,
  .my-apps-detail-tabs-content .reg-step-5-item-btn {
    padding: 0;
  }

  .my-apps-right-data-change .reg-step-1-form-container {
    width: 100%;
  }

  .my-apps-right-data-change .reg-step-2-btn {
    padding: 30px 0;
  }

  .director-pending-checks-list .my-apps-left {
    width: 100%;
    min-height: auto;
  }

  .director-pending-checks-list .my-apps-right {
    width: 100%;
  }

  .director-pending-checks-list .my-apps-left {
    padding: 10px 0 0;
  }

  .dir-pend-check-links a span {
    display: inline-block;
  }

  .director-pending-checks-list .my-apps-right .my-applications-title {
    padding: 15px 0;
  }

  .dit-pend-check-top {
    padding: 15px;
  }

  .dir-page-links-inner {
    margin-bottom: 20px;
  }

  .dir-pend-check-req-title h3 {
    padding: 15px;
    margin: 0;
  }

  .director-pending-checks-request
    .my-apps-detail-tabs-content
    .reg-step-5-item-line {
    padding: 0 15px;
  }

  .director-pending-checks-request .reg-step-btn,
  .director-pending-checks-request .dir-req-prev-next-btns {
    padding: 15px;
  }

  .accept-modal .accept-modal-block {
    width: calc(100% - 30px);
    padding: 40px;
  }

  .accept-modal-block-accepted {
    width: calc(100% - 30px) !important;
  }

  .file-loaded .file-loaded-block {
    width: calc(100% - 30px);
  }

  .map-text-main-info-slider {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 479px) {
  .main-nav-logo div {
    width: 140px;
    height: 40px;
  }

  .main-nav-links-mobile i,
  .main-nav-links-mobile i {
    font-size: 24px;
  }

  .header-sign-in-link {
    padding-right: 10px;
  }

  .main-info-left {
    padding: 30px 0 90px;
  }

  .main-info-left h1 {
    font-size: 32px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .main-info-left p {
    font-size: 16px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .main-info-left-links {
    flex-wrap: wrap;
  }

  .main-info-left .main-info-link {
    width: 100%;
  }

  .apply {
    margin-bottom: 20px;
  }

  .main-nav-links-navbar-mobile {
    top: 45px;
  }

  .toggle {
    top: 60px;
  }

  .main-nav-links-navbar-mobile ul li {
    padding: 0 0 0 40px;
  }

  .main-nav-mobile-languages {
    padding: 15px 0 5px;
  }

  .about-us-text,
  .how-it-works .container-expand {
    padding: 40px 0 40px;
  }

  .write-us .container-expand,
  .regulatory .container-expand {
    padding: 40px 15px 40px;
  }

  .how-it-works:before {
    height: 80px;
  }

  .how-it-works:after {
    height: 105px;
  }

  .write-us .container-expand form .write-us-inputs {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .write-us .container-expand form .write-us-inputs input {
    width: 100%;
    margin-bottom: 20px;
  }

  .write-us .container-expand form button {
    width: 100%;
  }

  .regulatory .container-expand .regulatory-items .regulatory-item {
    padding: 0;
  }

  .regulatory .container-expand .regulatory-link {
    width: 100%;
  }

  .footer-nav-container .container-expand .footer-nav ul li {
    width: 100%;
  }

  .footer-nav-container .container-expand .footer-nav ul li a {
    font-size: 16px;
  }

  .main-nav-link-2 {
    order: 2;
  }

  .main-nav-link-3 {
    order: 3;
  }

  .main-nav-link-4-B {
    order: 4;
  }

  .main-nav-link-5 {
    order: 5;
  }

  .footer-bottom .container-expand .footer-social i {
    font-size: 24px;
  }

  .footer-bottom .container-expand span,
  .footer-bottom .container-expand p {
    padding: 10px 0 0;
    text-align: center;
    margin: 0 auto;
  }

  .footer-bottom .container-expand p {
    margin-bottom: 10px;
  }

  .footer-logo-container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footer-logo-container span {
    margin: 0 !important;
    padding: 0 !important;
  }

  .faq-title {
    padding: 15px 0;
  }

  .faq-title h2 {
    font-size: 18px;
  }

  .hint-title {
    margin-bottom: 15px;
  }

  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
  }

  .faq-info .faq-right form button {
    width: 100%;
  }

  .docs-block .container-expand {
    padding: 20px 15px 30px;
  }

  .instructions-block .container-expand .instructions-items h5 {
    margin: 20px 0;
  }

  .instructions-block
    .container-expand
    .instructions-items
    .instructions-item-links {
    padding: 0;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-video {
    margin-bottom: 20px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-text-img {
    flex-wrap: wrap;
  }

  .instructions-inner-right-text-container {
    width: 100%;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-text
    .instructions-inner-right-text-img
    .instructions-inner-right-img-container {
    width: 180px;
    min-width: 180px;
    margin: 0 auto 25px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns {
    padding: 25px 0;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns
    .prev,
  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns
    .next {
    width: 55px;
  }

  .instructions-inner-block-container
    .instructions-inner-right
    .instructions-inner-right-pages-btns
    .all-instructions {
    width: 150px;
  }

  .statistics-items .statistics-item {
    padding: 20px;
  }

  .map-space {
    height: 380px;
  }

  .map-space .map-search {
    top: 20px;
  }

  .map-text-title h3,
  .map-text-title span {
    font-size: 16px;
  }

  .map-text-status {
    padding: 10px 0;
  }

  .map-text-link {
    margin: 10px auto;
    width: calc(100% - 30px);
  }

  .map-text-statistics {
    margin-bottom: 30px;
  }

  .slider {
    height: 215px;
    padding-top: 200px;
  }

  .slider label {
    height: 75px;
  }

  .map-text-main-info-slider {
    margin-bottom: 110px;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: column;
  }

  .recovery-block .container-expand {
    padding: 25px 0 0;
  }

  .recovery-block .hint-title {
    margin-bottom: 20px;
  }

  .recovery-input-container input {
    font-size: 24px;
    text-overflow: ellipsis;
  }

  .login-block .recovery-notice p {
    margin-bottom: 10px;
  }

  .reg-step-map-title {
    border-top: none;
    padding: 0 0 20px;
  }

  .reg-step-btn {
    padding: 20px 0;
  }

  .dropzone {
    height: 220px;
  }

  .dz-image {
    height: 190px;
    width: 260px;
  }

  .issuing-authority-container {
    width: 75%;
  }

  .reg-step-2-btn {
    padding: 30px 15px;
  }

  .reg-step-form-passport-info {
    flex-wrap: wrap;
  }

  .pass-serial,
  .pass-number {
    width: 60%;
  }

  .pass-serial {
    margin-bottom: 20px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone {
    height: 180px;
  }

  .reg-step-2-dropzones .reg-step-form-item .dropzone .dz-image {
    height: 160px;
  }

  .reg-step-2-dropzones .reg-step-form-item-text {
    width: 100%;
  }

  .checkbox-container label {
    display: inline-block;
  }

  .dropzone-step-4 {
    height: 155px;
  }
  .dropzone-step-4 .dz-image {
    height: 130px;
  }

  .reg-step-5-item-line {
    padding: 0;
  }

  .reg-step-5-item-img img {
    margin-bottom: 5px;
  }

  .reg-final-like-text h3 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .check-block-item-bottom .reg-step-form-item {
    width: 100%;
  }

  .check-results-username {
    padding: 20px 0;
  }

  .check-results-main-info i {
    font-size: 32px;
  }

  .check-results-notice-btn-mobile {
    display: block;
  }

  .check-results-child-info-item span {
    width: 50%;
  }

  .check-results-table tbody td span {
    font-size: 14px;
  }

  .check-results-table thead tr h5 {
    font-size: 14px;
  }

  .my-apps-left-links a {
    width: 100%;
  }

  .my-apps-card-text-item span,
  .my-apps-card-text-item p {
    white-space: normal;
  }

  .my-apps-card-text-item p {
    min-width: 50%;
  }

  .my-apps-card-main-status-inner {
    padding: 15px 45px;
  }

  .my-applications-title-hidden-details {
    flex-wrap: wrap;
  }
  .my-applications-title-hidden-details h2 {
    width: 100%;
  }

  .my-apps-details-status-info {
    margin: 0;
  }

  .reg-step-5-item-img-details-2 img {
    width: 100% !important;
  }

  .reg-step-5-item-img-details-2 {
    padding: 0 !important;
    background: transparent !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-child-info-item {
    padding: 0 !important;
  }

  .my-apps-detail-tabs-content-details-3 .check-results-table h4 {
    width: 60%;
  }

  .check-results-child-info-item span {
    width: 20%;
  }

  .my-app-add-step-1-block .reg-step-btn a {
    width: 50%;
  }

  .my-app-add-step-1-block .recovery-label {
    white-space: normal;
  }

  .my-app-add-step-3-block .reg-step-5-item-img img {
    width: 100%;
  }

  .my-app-add-step-final-block .reg-final-like {
    padding: 0 10%;
  }

  .my-apps-right-data .reg-step-5-item-img-details-2 img {
    width: 100% !important;
    margin-bottom: 5px !important;
  }

  .my-apps-right-data-change .reg-step-2-btn {
    padding: 15px 0;
  }

  .dir-pend-check-links .dir-link-active {
    margin-right: 5px;
  }

  .dir-pend-nav .dir-pend-search-input {
    width: 60%;
  }

  .dir-pend-nav .dir-pend-search-select {
    width: 35%;
  }

  .dir-page-links-inner {
    width: 90%;
  }

  .dir-pend-check-req-title h3 {
    font-size: 18px;
  }

  .director-pending-checks-request .my-apps-detail-tabs-links a {
    width: 25%;
    font-size: 14px;
  }

  .director-pending-checks-request .dir-req-prev-next-btns a {
    width: 50%;
  }

  .director-pending-checks-request .dir-req-prev-next-btns .prev,
  .director-pending-checks-request .dir-req-prev-next-btns .next {
    width: 25%;
  }

  .accept-modal .accept-modal-block {
    padding: 30px;
  }

  .file-loaded .file-loaded-block {
    padding: 30px;
  }

  .dir-school-main .slider {
    height: 215px;
    padding-top: 200px;
  }

  .dir-school-main .slider label {
    height: 70px;
  }

  .map-text-main-info-slider {
    margin-bottom: 90px;
  }

  .map-text-contacts .map-text-contacts-phone {
    flex-direction: column !important;
  }

  .dir-school-main .dropzone > div {
    max-width: 33.3333%;
  }
}
@media (min-width: 320px) and (max-width: 425px) {
  .rented-data {
    position: absolute;
    left: 50px;
    width: 300px;
    font-size: 16px !important;
  }
  .main-info-left-links {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .rented-data {
    position: absolute;
    left: 123px !important;
    width: 686px !important;
  }
}
</style>
