<template>
  <div class="container mt-5">
    <h2 class="video-title">{{$t('videos.title')}}</h2>
    <div >
  <div class="container">
  <div class="row">
    <div class="col" v-for="video in videos.slice(0,3)" :key="video.id">
      <iframe
        class="home_video"
				:src="video.url"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen>

			</iframe>
             <h5 id = 'videos_title' className={styles.videoItemTitle}>{{video.name}}</h5>
    </div>
  </div>
</div>
    </div>
			<router-link :to="`/${$i18n.locale}/videos`" class="btn btn-primary my-btn btn_video">{{ $t('videos.video_btn') }}</router-link>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            videos: []
        }
    },
    methods: {
        getVideos() {
            axios.get('https://kabinet.okuukitebi.edu.kg/api/videos/?category=3').then((response) => {
                this.videos = response.data
            })
        }
    },
    mounted() {
        this.getVideos()
    }
}
</script>

<style scoped>

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#videos_title {
  padding: 5px 0;
width: 70%;
text-align: center;
}
.video-title {
  position: relative;
  top: 30px;
  bottom: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  text-align: center;
}
.home_video {
  border-radius: 5px;
  font-size: 100%;
}
@media (min-width: 320px) and (max-width: 425px) {
.video-title {
  position: relative;
  top: 30px;
  bottom: 20px;
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 5px;
}
.home_video {
  width: 280px;
}
}
.my-btn {
  width: 60%;
  display: block;
  margin:0 auto;
  margin-bottom: 30px;
}
</style>