<template>
   <div class="message" v-if="showMessage">
    <p>{{ $t('nav_message.title') }}<br>
      <span class="button-MessageIcon btn-ctrl">CTRL</span> + <span class="button-MessageIcon btn-shift">SHIFT</span> + <span class="button-MessageIcon btn-r">R</span>
    </p>
    
    <button class="hide-button" @click="hideMessage">{{ $t("nav_message.btn") }}</button>
  </div>
</template>

<script>
export default {
    data () {
    return { langs: ['kg', 'ru'], showMessage: !localStorage.getItem('hideMessage') }
  },
   methods: {
    hideMessage() {
      this.showMessage = false;
      const now = new Date();
      const expiryDate = new Date(now.getTime()+ 24 * 60 * 60 * 1000);
      localStorage.setItem('hideMessage', expiryDate);
    },
    
    setLocale(locale){
      this.$i18n.locale = locale
      console.log('locale', locale)
      this.$router.push({
        params: { lang: locale}
      })
    }
  },
  mounted(){
      const hideMessage = localStorage.getItem("hideMessage");
      if(hideMessage && new Date(hideMessage) < new Date()){
        this.showMessage = true;
        localStorage.removeItem('hideMessage');
      }
    },
 
}
</script>

<style scoped>
.message {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 16px;
  max-width: 250px;
  color: #333;
  background-color: #f9f9f9f1;
  border-radius: 15px 15px 0px 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 500;
}

.message p {
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.hide-button {
  display: block;
  padding: 2px 12px;
  font-size: 15px;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.39);
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.hide-button:hover {
  background-color: #032e5c;
}
.button-MessageIcon {
  border: none;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  background-color: white; 
  color: black; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}
.message .btn-ctrl {
  width: 25%;
}
.message .btn-shift {
  width: 25%;
}

.message .btn-r {
  width: 12%;
}
</style>