import i18n from "@/i18n";
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("../views/NewsView.vue"),
      },
      {
        path: "news/:id",
        name: "news-details",
        component: () => import("../views/NewsDetails.vue"),
      },
      {
        path: "batken-school-codes",
        name: "batken-school-codes",
        component: () => import("../views/school-codes-view/BatkenView.vue"),
      },
      {
        path: "bishkek-school-codes",
        name: "bishkek-school-codes",
        component: () => import("../views/school-codes-view/BishkekView.vue"),
      },
      {
        path: "osh-city-school-codes",
        name: "osh-city-school-codes",
        component: () => import("../views/school-codes-view/OshCityView.vue"),
      },
      {
        path: "zhalal-abad-school-codes",
        name: "zhalal-abad-school-codes",
        component: () =>
          import("../views/school-codes-view/ZhalalAbadView.vue"),
      },
      {
        path: "naryn-school-codes",
        name: "naryn-school-codes",
        component: () => import("../views/school-codes-view/NarynView.vue"),
      },
      {
        path: "talas-school-codes",
        name: "talas-school-codes",
        component: () => import("../views/school-codes-view/TalasView.vue"),
      },
      {
        path: "chui-school-codes",
        name: "chui-school-codes",
        component: () => import("../views/school-codes-view/ChuiView.vue"),
      },
      {
        path: "ik-school-codes",
        name: "ik-school-codes",
        component: () => import("../views/school-codes-view/IssykkulView.vue"),
      },
      {
        path: "osh-school-codes",
        name: "osh-school-codes",
        component: () => import("../views/school-codes-view/OshView.vue"),
      },
      {
        path: "rent-payment",
        name: "rent-payment",
        component: () => import("../views/RentPaymentView.vue"),
      },
      {
        path: "regulations",
        name: "regulations",
        component: () => import("../views/RegulationView.vue"),
      },
      {
        path: "contacts",
        name: "contacts",
        component: () => import("../views/ContactsView.vue"),
      },

      {
        path: "finances",
        name: "finances",
        component: () => import("../views/FinancesViews.vue"),
      },
      {
        path: "questions-and-answers",
        name: "questions-and-answers",
        component: () => import("../views/QuestionsAndAnswersView.vue"),
      },
      {
        path: "videos",
        name: "videos",
        component: () => import("../views/VideosViews.vue"),
      },

      {
        path: "open-data",
        name: "open-data",
        component: () => import("../views/OpenData.vue"),
      },

      {
        path: "lending-about",
        name: "lending-about",
        component: () => import("../views/AboutLending.vue"),
      },
      // {
      //   path: "pisa",
      //   name: "pisa",
      //   component: () => import("../views/AboutPisa.vue"),
      // },
      // {
      //   path: "libraries-info",
      //   name: "libraries-info",
      //   component: () => import("../views/LibrariesInfoView.vue"),
      // },
      // {
      //   path: "libraries-districts/:id",
      //   name: "libraries-districts-details",
      //   component: () => import("../views/LibrariesDistrictsDetails.vue"),
      // },
      // {
      //   path: "school-details/:id",
      //   name: "school-details",
      //   component: () => import("../views/SchoolDetail.vue"),
      // },
      // {
      //   path: "school-news-detail/:id",
      //   name: "school-news-detail",
      //   component: () => import("../views/SchoolNewsDetail.vue"),
      // },
      // {
      //   path: "libraries-info/school-news/:id",
      //   name: "libraries-new-details",
      //   component: () => import("../views/LibrariesNewsDetails.vue"),
      // },
      // {
      //   path: "libraries-info/school-events/:id",
      //   name: "libraries-events-details",
      //   component: () => import("../views/LibrariesEventsDetails.vue"),
      // },\
      // {
      //   path: "school-news",
      //   name: "school-news",
      //   component: () => import("../views/LibrariesNewsListAll.vue"),
      // },
      // {
      //   path: "school-events",
      //   name: "school-events",
      //   component: () => import("../views/LibrariesEventListAll.vue"),
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
