<template>
  <footer id="footer">
    <div class="container">
      <h3>ОКУУ КИТЕБИ</h3>
      <p>{{ $t("footer.description") }}</p>
      <div class="copyright">
        &copy; Copyright <strong><span> ОКУУ КИТЕБИ</span></strong
        >. {{ $t("footer.copyright") }}
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/green-free-one-page-bootstrap-template/ -->
        <a href="/" class="developers"> {{ $t("footer.designed_by") }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
#footer {
  background: #0088e1;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}

#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #367db7;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #367db7;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 13px;
}
.developers {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.developers:hover {
  color: #eee;
}
@media (min-width: 375px) and (max-width: 425px) {
  #footer h3 {
    font-size: 25px;
  }
}
@media (min-width: 300px) and (max-width: 375px) {
  .form-control::placeholder {
    font-size: 55%;
  }
  .form-control {
    width: 75%;
  }
  #footer h3 {
    font-size: 20px;
  }
}
</style>
